<template>
  <div class="contact">
    <section class="workflow workflow-spacing">
      <div class="workflow-content mb-3 py-4 bg-color">
        <div class="container title-center">
          <h2>WORKFLOW <span class="breakline">服務流程</span></h2>
          <p class="mt-2">從腳本討論至影片完成，<span class="breakline">製作期依照案件內容而定 。</span>
            <br>
              <span class="text-en">
                The production period will base on the content of each case.
              </span>
          </p>
        </div>
      </div>
      <div class="container">
        <div class="work-title">
          <img src="@/assets/images/workflowicon/A.jpg" class="order" alt="工作流程1">
          <div class="order-line"></div>
          <div class="work-left-space">
            <div class="d-flex">
              <img src="@/assets/images/workflowicon/1.png" class="mr-2" alt="需求討論">
              <h3>需求討論
                <span>Discuss for demands</span>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ul class="detail mt-3">
                  <li><span class="comma">‧ </span>製作方向與內容</li>
                  <li><span class="comma">‧ </span>影片目標受眾(TA)</li>
                  <li><span class="comma">‧ </span>影片播放平台
                    <p class="example">
                      EX：網站、電視廣告、<br>社群媒體廣告、展場...
                    </p>
                  </li>
                  <li>‧ 影片播放地區</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="detail mt-3 text-en">
                  <li><span class="comma">‧ </span>Producing concept
                  <span class="breakpoint">and content</span></li>
                  <li><span class="comma">‧ </span>Target Audience</li>
                  <li><span class="comma">‧ </span>Streaming media
                    <p class="example">
                      EX：Web,TVC,CF,Social media,etc.
                    </p>
                  </li>
                  <li><span class="comma">‧ </span>Region of streaming</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="work-title">
          <img src="@/assets/images/workflowicon/B.jpg" class="order" alt="工作流程2">
          <div class="order-line"></div>
          <div class="work-left-space">
            <div class="d-flex">
              <img src="@/assets/images/workflowicon/2.png" class="mr-2" alt="初步提案與報價">
              <h3>初步提案與報價
                <span>Proposal and Quotation</span>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ul class="detail mt-3">
                  <li><span class="comma">‧ </span>腳本內容設計</li>
                  <li><span class="comma">‧ </span>預算費用規劃</li>
                  <li><span class="comma">‧ </span>影片製作時程規劃</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="detail mt-3 text-en">
                  <li><span class="comma">‧ </span>Scriptwriting</li>
                  <li><span class="comma">‧ </span>Budgeting</li>
                  <li><span class="comma">‧ </span>Scheduling</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="work-title">
          <img src="@/assets/images/workflowicon/C.jpg" class="order" alt="工作流程3">
          <div class="order-line"></div>
          <div class="work-left-space">
            <div class="d-flex">
              <img src="@/assets/images/workflowicon/3.png" class="mr-2" alt="簽約與腳本確認">
              <h3>簽約與腳本確認
                <span>Agreement and Script confirmation</span>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ul class="detail mt-3">
                  <li><span class="comma">‧ </span>簽訂合約</li>
                  <li><span class="comma">‧ </span>詳細腳本確認</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="detail mt-3 text-en">
                  <li><span class="comma">‧ </span>Agreement sign up</li>
                  <li><span class="comma">‧ </span>Script confirmation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="work-title">
          <img src="@/assets/images/workflowicon/D.jpg" class="order" alt="工作流程4">
          <div class="order-line"></div>
          <div class="work-left-space">
            <div class="d-flex">
              <img src="@/assets/images/workflowicon/4.png" class="mr-2" alt="前置與拍攝">
              <h3>前置與拍攝
                <span>Preparation and Shooting</span>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ul class="detail mt-3">
                  <li><span class="comma">‧ </span>現場勘景</li>
                  <li><span class="comma">‧ </span>製作分鏡腳本</li>
                  <li><span class="comma">‧ </span>製作細項確認</li>
                  <li><span class="comma">‧ </span>正式拍攝</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="detail mt-3 text-en">
                  <li><span class="comma">‧ </span>Site survey</li>
                  <li><span class="comma">‧ </span>Storyboard</li>
                  <li><span class="comma">‧ </span>Production detail
                  <span class="ipad-breakpoint">confirmation</span></li>
                  <li><span class="comma">‧ </span>Filming</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="work-title">
          <img src="@/assets/images/workflowicon/E.jpg" class="order" alt="工作流程5">
          <div class="order-line"></div>
          <div class="work-left-space">
            <div class="d-flex">
              <img src="@/assets/images/workflowicon/5.png" class="mr-2" alt="後期製作">
              <h3>後期製作
                <span>Post-production</span>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ul class="detail">
                  <li class="edit mt-3">影片2次修改為限</li>
                  <li class="mt-3">
                    <div class="d-flex align-items-center">
                      <p class="process mr-2">Acopy</p>
                      <p class="percent text-s-blue">完成度60%</p>
                    </div>
                    <p class="content">影片主要架構、基本套色
                      <br><span>音樂、動畫...等調性確認(依需求而定)</span></p>
                  </li>
                </ul>
                <ul class="detail">
                  <li class="mt-1">
                    <div class="d-flex align-items-center">
                      <p class="process mr-2">Bcopy</p>
                      <p class="percent text-s-blue">完成度80%</p>
                    </div>
                    <p class="content">針對Acopy修改意見的調整、
                      <br><span>旁白風格、調光...等調性確認(依需求而定)</span></p>
                  </li>
                </ul>
                <ul class="detail">
                  <li class="mt-1">
                    <div class="d-flex align-items-center">
                      <p class="process mr-2">定剪</p>
                      <p class="percent text-s-blue">完成度100%</p>
                    </div>
                    <p class="content">混音、字幕、調光調色</p>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="detail text-en">
                  <li class="detail-red mt-3">Limit of film modification：
                    <span class="breakpoint-limit">2 times</span>
                  </li>
                  <li>‧ Acopy (60% complete)：
                    <p class="post-space">Confirmation for framework,</p>
                    <p class="post-space">primary color grading,audio,</p>
                    <p class="post-space">2DFX/VFX,ect.</p>
                  </li>
                  <li class="main-break">‧ Bcopy (80% complete,
                    <span class="breakpoint">adjustment for Acopy)：</span>
                    <p class="post-space">Confirmation for V.O.,
                      <span class="breakpoint-limit">color grading,ect.</span>
                    </p>
                  </li>
                  <li>‧ Final (100% complete)：
                    <p class="post-space">Audio Mixing, Closed Caption,
                      <span class="breakpoint-limit">Color Grading.</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="work-title">
          <img src="@/assets/images/workflowicon/F.jpg" class="order" alt="工作流程6">
          <div class="work-left-space">
            <div class="d-flex">
              <img src="@/assets/images/workflowicon/6.png" class="mr-2" alt="完成交片">
              <h3>完成交片
                <span>Production complete</span>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ul class="detail">
                  <li class="cloud text-center mt-3">雲端交付完整影片
                    <p>
                      (或依合約簽訂方式交付)
                    </p>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="detail text-en">
                  <li class="mt-3">‧ Film Delivery
                    <p class="example">
                      (By Cloud or else according
                      <span class="breakpoint-limit">to agreement.)</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="customer-info px-2 px-md-0">
      <div class="container title-center">
        <h2>Ready to Start? <span class="breakline">等不及要開始了嗎?</span></h2>
        <p class="mt-3">等不及開始和我們<span class="breakline">一起執行新專案了嗎?</span></p>
        <p>我們非常期待收到您的來信!</p>
        <p class="text-en">Let's get started on <span class="breakline">a new project.</span></p>
        <p class="text-en">Looking forward to <span class="breakline">hearing from you!</span></p>
        <p><span class="alert-text">※為必填項目</span></p>
        <ValidationObserver v-slot="{ invalid }">
          <form class="mt-4 d-flex flex-column" @submit.prevent = "submitSheet">
            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
              <div class="form-group row">
                <label class="col-xl-2 col-form-label" for="name">姓名 Name※</label>
                <div class="col-xl-10">
                  <input class="form-control input-style" type="text" id="name"
                  v-model="customer.name">
                  <span class="d-block alert-text text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
              <div class="form-group row">
                <label class="col-xl-2 col-form-label" for="email">信箱 E-mail※</label>
                <div class="col-xl-10">
                  <input class="form-control input-style" type="email" id="email"
                  inputmode="email"
                  v-model="customer.email">
                  <span class="d-block alert-text text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider name="email" rules="tel" v-slot="{ errors }">
              <div class="form-group row">
                <label class="col-xl-2 col-form-label" for="tel">連絡電話 TEL</label>
                <div class="col-xl-10">
                  <input class="form-control input-style" type="tel" id="tel"
                  inputmode="tel"
                  v-model="customer.tel">
                  <span class="d-block alert-text text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group row">
              <label class="col-xl-2 col-form-label" for="company">公司/單位 Company</label>
              <div class="col-xl-10">
                <input class="form-control input-style" type="text" id="company"
                v-model="customer.company">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-2 col-form-label" for="role">職稱 Role</label>
              <div class="col-xl-10">
                <input class="form-control input-style" type="text" id="role"
                v-model="customer.role">
              </div>
            </div>
            <ValidationProvider name="message" rules="required" v-slot="{ errors }">
              <div class="form-group row">
                <label class="col-xl-2 col-form-label" for="message">需求※ <br>Your message</label>
                <div class="col-xl-10">
                  <textarea class="form-control input-style" name="message" id="message"
                  cols="30" rows="10" v-model="customer.message"></textarea>
                  <span class="d-block alert-text text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group row">
              <label class="col-xl-2 col-form-label" for="budget">預算 Budget</label>
              <div class="col-xl-10">
                <select class="select-width" name="budget" id="budget"
                v-model="customer.budget">
                  <option value="">-- 請選擇預算 --</option>
                  <option value="30萬以下">30萬以下</option>
                  <option value="30萬-60萬">30萬-60萬</option>
                  <option value="60萬-100萬">60萬-100萬</option>
                  <option value="100萬-150萬">100萬-150萬</option>
                  <option value="150">150萬以上</option>
                </select>
              </div>
            </div>
            <button class="btn btn-s-blue btn-style
            align-self-xl-end align-self-center mt-3 mt-xl-0" type="submit"
            :disabled = "invalid">
              確認內容並送出 Send
            </button>
          </form>
        </ValidationObserver>
      </div>
    </section>
    <section class="contact-us px-1 px-md-0">
      <div class="container">
        <h2 class="mb-3">CONTACT US <span class="breakline">聯絡我們</span></h2>
        <div class="row justify-content-between align-items-start">
          <div class="col-xl-7 d-block d-md-flex align-items-start">
            <p class="label-style mr-md-3">Address</p>
            <p>710台南市永康區富強路一段98巷38弄3號1樓
                <br>
                  <span class="text-en">
                    Taiwan, Tainan, 1F., No. 3, Aly. 38, Ln. 98,
                    Sec. 1, Fuqiang Rd., Yongkang Dist
                  </span>
            </p>
          </div>
          <div class="col-xl-5 d-block d-md-flex mt-3 mt-xl-0">
            <p class="label-style mr-md-3">E-mail</p>
            <p class="text-en">sdstudio@sdstudiotw.com</p>
          </div>
        </div>
        <Map class="mt-3"></Map>
        <div class="d-flex align-items-center mt-3">
          <p class="label-style mr-3">Follow us</p>
          <div class="social">
            <a class="mr-3" href="https://www.facebook.com/selfdirectedstudio" target="_blank"><i class="fab fa-facebook-f text-black"></i></a>
            <a class="mr-3" href="https://www.youtube.com/channel/UCGawabA6bnVLKxzzDdd5r0Q" target="_blank"><i class="fab fa-youtube text-black"></i></a>
            <a href="https://www.instagram.com/selfdirectedstudio/" target="_blank"><i class="fab fa-instagram text-black"></i></a>
          </div>
        </div>
      </div>
    </section>
    <!-- 寄信成功 -->
    <div id="checkmodal" class="modal fade" data-backdrop="static">
      <div class="modal-dialog check-pos">
        <div class="modal-content check-space d-flex flex-column align-items-center">
          <AlertMessage :state="'success'"></AlertMessage>
          <div class="m-3 text-success font-weight-bold">{{ sendState }}</div>
          <router-link to="/" class="btn btn-s-blue" data-dismiss="modal"
          @click.native="toTop">回首頁</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import Map from '@/components/Map.vue';
import AlertMessage from '@/components/AlertMessage.vue';
import emailjs from 'emailjs-com'; // 載入EmailJS

const { GoogleSpreadsheet } = require('google-spreadsheet');
// eslint-disable-next-line import/no-unresolved
const creds = require('../../credential.json'); // 憑證匯入

export default {
  data() {
    return {
      customer: {
        name: '',
        email: '',
        tel: '',
        company: '',
        role: '',
        message: '',
        budget: '',
      },
      sendState: '',
    };
  },
  components: {
    Map,
    AlertMessage,
  },
  methods: {
    async writeRow(item) {
      const doc = new GoogleSpreadsheet('1FQ_XKOpnARylAMuz4yk7CrFTT39KzlI6rrvhq-7OQaM');
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();
      // 讀取excel裡的第一個表單↓
      const sheet = await doc.sheetsByIndex[0];
      // 新增新資料↓
      await sheet.addRow(item);
    },
    submitSheet() {
      const vm = this;
      const data = vm.customer;
      vm.$bus.$emit('loading', true);
      (async () => {
        await vm.writeRow(data);
        await vm.emailSend(data);
      })();
    },
    emailSend(data) {
      const vm = this;
      const serviceId = 'skyice';
      const templateId = 'template_sd';
      emailjs.send(serviceId, templateId, data).then(() => {
        vm.$bus.$emit('loading', false);
        vm.sendState = '寄信成功';
        $('#checkmodal').modal('show');
      }).catch((error) => { console.log(error); });
    },
    toTop() {
      window.scroll(0, 0);
    },
  },
  created() {
    const vm = this;
    vm.$bus.$emit('changeNav', 'contact');
  },
};
</script>
